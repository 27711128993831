import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import ResizeObserver from "react-resize-detector"
import SiteHeader from "./SiteHeader"
import SiteFooter from "./SiteFooter"
import MobileNavigation, { useMobileNavigation } from "./MobileNavigation"

function Layout({ location, children }) {
  const [activeMenu, setActiveMenu] = useState(null)
  const mobileNavigationOepn = useMobileNavigation(state => state.open)
  const setMobileNavigationOpen = useMobileNavigation(state => state.setOpen)

  useEffect(() => {
    if (location?.pathname) {
      setActiveMenu(null)
      setMobileNavigationOpen(false)
    }
  }, [location])

  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  const hasHeaderMarginTop = ["/"].includes(location.pathname)

  return (
    <ParallaxProvider>
      <SiteHeader location={location} title={title} />

      <main className={`pt-16 lg:pt-20`}>{children}</main>

      <SiteFooter location={location} title={title} />
      <div id="portal-backdrop" />
      <MobileNavigation />
      <ResizeObserver />
    </ParallaxProvider>
  )
}

export default Layout
