import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"

export default function MegaMenu({ items, type = "default" }) {
  const data = useStaticQuery(graphql`
    query MegaMenuQuery {
      allWpCase(limit: 1, sort: { order: DESC, fields: date }) {
        nodes {
          title
          uri
          id
        }
      }
      allWpPost(limit: 1, sort: { order: DESC, fields: date }) {
        nodes {
          title
          uri
          id
        }
      }
    }
  `)

  const recentCase = get(data, "allWpCase.nodes.0", null)
  const recentPost = get(data, "allWpPost.nodes.0", null)

  if (type === "services") {
    return (
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap -mx-4 -my-6">
          {items &&
            items.map(item => {
              return (
                <div
                  className="w-1/3 lg:w-1/3 my-6 hover:opacity-75"
                  key={item.key}
                >
                  <Link to={item.url}>
                    <div key={item.key} className="px-4">
                      <h4 className="font-medium text-gray-900 border-b border-gray-900 border-dotted inline-block">
                        {item.label}
                      </h4>
                      <p className="text-gray-500 text-base mt-3 break-words line-clamp-2">
                        {item.description}
                      </p>
                    </div>
                  </Link>
                </div>
              )
            })}
        </div>
      </div>
    )
  }

  if (type === "cases" || type === "insights") {
    const renderTitle = () => {
      if (type === "cases") {
        return "Featured case study"
      }
      return "Featured insight"
    }

    const renderFeaturedItem = () => {
      if (type === "cases") {
        return (
          <Link to={recentCase.uri} className="cursor-pointer group">
            <div>
              <h3 className="text-gray-900 text-lg leading-snug font-medium group-hover:text-primary break-words">
                {recentCase.title}
              </h3>
              <div className="text-gray-500 mt-5">
                <span className="text-gray-900 border-0 border-gray-900 border-dotted inline-block">
                  더 알아보기
                </span>
              </div>
            </div>
          </Link>
        )
      }
      if (type === "insights") {
        return (
          <Link to={recentPost.uri} className="cursor-pointer group">
            <div>
              <h3 className="text-gray-900 text-lg leading-snug font-medium group-hover:text-primary break-words">
                {recentPost.title}
              </h3>
              <div className="text-gray-500 mt-5">
                <span className="text-gray-900 border-0 border-gray-900 border-dotted inline-block">
                  더 알아보기
                </span>
              </div>
            </div>
          </Link>
        )
      }
    }

    return (
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap justify-between -mx-8">
          <div className="px-8 w-6/12">
            <div className="flex flex-row flex-wrap -mx-8">
              <div className="flex-1 px-8 max-w-sm">
                <h4 className="text-sm text-gray-500 mb-3">{renderTitle()}</h4>
                {renderFeaturedItem()}
              </div>
            </div>
          </div>
          <div className="px-8 w-6/12 ml-auto">
            <h4 className="text-sm text-gray-500 mb-3">Topics</h4>
            <div className="flex flex-row flex-wrap">
              {items &&
                items.map(item => {
                  return (
                    <div key={item.key} className="hover:opacity-75 w-1/2 mb-3">
                      <Link to={item.url} key={item.key}>
                        <span className="text-gray-900 border-0 border-gray-900 border-dotted inline-block">
                          {item.label}
                        </span>
                      </Link>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap -mx-8 justify-center">
          {items &&
            items.map(item => {
              return (
                <div key={item.key} className="px-8 hover:opacity-75">
                  <Link to={item.url} key={item.key}>
                    <span className="text-gray-900 border-0 border-gray-900 border-dotted inline-block">
                      {item.label}
                    </span>
                  </Link>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
