exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cases-[category]-js": () => import("./../../../src/pages/cases/[category].js" /* webpackChunkName: "component---src-pages-cases-[category]-js" */),
  "component---src-pages-consultants-js": () => import("./../../../src/pages/consultants.js" /* webpackChunkName: "component---src-pages-consultants-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prvaicy-policy-js": () => import("./../../../src/pages/prvaicy-policy.js" /* webpackChunkName: "component---src-pages-prvaicy-policy-js" */),
  "component---src-pages-services-human-resource-development-js": () => import("./../../../src/pages/services/human-resource-development.js" /* webpackChunkName: "component---src-pages-services-human-resource-development-js" */),
  "component---src-pages-services-human-resource-management-js": () => import("./../../../src/pages/services/human-resource-management.js" /* webpackChunkName: "component---src-pages-services-human-resource-management-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-innovation-js": () => import("./../../../src/pages/services/innovation.js" /* webpackChunkName: "component---src-pages-services-innovation-js" */),
  "component---src-pages-services-manufacturing-js": () => import("./../../../src/pages/services/manufacturing.js" /* webpackChunkName: "component---src-pages-services-manufacturing-js" */),
  "component---src-pages-services-organization-development-js": () => import("./../../../src/pages/services/organization-development.js" /* webpackChunkName: "component---src-pages-services-organization-development-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/BlogPostArchive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-category-js": () => import("./../../../src/templates/BlogPostCategory.js" /* webpackChunkName: "component---src-templates-blog-post-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-archive-js": () => import("./../../../src/templates/CaseArchive.js" /* webpackChunkName: "component---src-templates-case-archive-js" */),
  "component---src-templates-case-category-js": () => import("./../../../src/templates/CaseCategory.js" /* webpackChunkName: "component---src-templates-case-category-js" */),
  "component---src-templates-case-single-js": () => import("./../../../src/templates/CaseSingle.js" /* webpackChunkName: "component---src-templates-case-single-js" */),
  "component---src-templates-mention-archive-js": () => import("./../../../src/templates/MentionArchive.js" /* webpackChunkName: "component---src-templates-mention-archive-js" */),
  "component---src-templates-mention-single-js": () => import("./../../../src/templates/MentionSingle.js" /* webpackChunkName: "component---src-templates-mention-single-js" */),
  "component---src-templates-notice-archive-js": () => import("./../../../src/templates/NoticeArchive.js" /* webpackChunkName: "component---src-templates-notice-archive-js" */),
  "component---src-templates-notice-single-js": () => import("./../../../src/templates/NoticeSingle.js" /* webpackChunkName: "component---src-templates-notice-single-js" */)
}

