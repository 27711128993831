import React, { useEffect } from "react"
import { Link } from "gatsby"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import create from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "lib/zustand"
import { get, orderBy } from "lodash"

export const useMobileNavigation = create(
  devtools(
    immer(set => ({
      open: false,
      setOpen: active =>
        set(draft => {
          draft.open = active
        }),
    }))
  )
)

const MENU = [
  {
    key: "about",
    label: "HPC컨설팅 소개",
    url: "/about",
  },
  {
    key: "consultants",
    label: "컨설턴트 소개",
    url: "/consultants",
  },
  {
    key: "serivces",
    label: "컨설팅 서비스",
    children: [
      {
        key: "human-resource-management",
        label: "인적자원관리 (HRM)",
        description:
          "기업 특성이 조직, 전략, 문화, 구성원, 업무 등에 정합 되도록 돕습니다.",
        url: "/services/human-resource-management",
      },
      {
        key: "human-resource-development",
        label: "인적자원개발 (HRD)",
        description:
          "급변하는 환경 속에서 조직이 기술 변화에 신속히 대응하고 구성원의 직무역량을 강화 할 수 있도록 돕습니다.",
        url: "/services/human-resource-development",
      },
      {
        key: "organization",
        label: "조직개발",
        url: "/services/organization-development",
        description:
          "외부환경 변화, 기업전략 및 핵심경쟁력을 연계하여 조직체계 및 인적자원에 대한 개발이 이루어져야 합니다.",
      },
      {
        key: "innovation",
        label: "일터혁신",
        url: "/services/innovation",
        description:
          "협력적 노사관계를 기반으로 근로자의 참여에 의하여 작업조직과 작업방식을 지속적으로 개선함으로써 생산성과 근로생활의 질 향상을 지원합니다.",
      },
      {
        key: "manufacturing",
        label: "제조혁신",
        url: "/services/manufacturing",
        description:
          "대기업 공정관리자 출신 기술사와 스마트공장 전문가가 풍부한 현장경험과 노하우를 바탕으로 작업방식과 작업조직의 혁신을 지원합니다. ",
      },
    ],
  },
  {
    key: "casestudies",
    label: "컨설팅 성공사례",
    url: "/cases",
  },
  { key: "insights", label: "인사이트", url: "/insights" },
  { key: "newsroom", label: "보도자료", url: "/newsroom" },
  { key: "notice", label: "공지사항", url: "/notices" },
]

export default function MobileNavigation() {
  const open = useMobileNavigation(state => state.open)

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden", "fixed")
    } else {
      document.body.classList.remove("overflow-hidden", "fixed")
    }
  }, [open])

  return (
    <div
      className={`lg:hidden inset-0 w-full h-full z-40 bg-white ${
        open ? "fixed" : "hidden"
      }`}
    >
      <div className="absolute inset-0 w-full h-full overflow-scroll z-50">
        <div className="pt-16">
          {MENU.map(item => {
            if (item?.children) {
              return (
                <div key={item.key} className="border-b">
                  <div>
                    <div className="px-4 py-3">
                      <h4 className="text-gray-500 font-medium text-sm">
                        {item.label}
                      </h4>
                    </div>
                    <div>
                      {item.children.map(item => {
                        return (
                          <Link
                            key={item.key}
                            to={item.url}
                            className="pl-8 pr-4 py-3 flex flex-row justify-between"
                          >
                            <h4 className="text-gray-900 font-medium text-lg">
                              {item.label}
                            </h4>
                            <div>
                              <ChevronRightIcon className="w-4 h-4 text-gray-500" />
                            </div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            }

            return (
              <div key={item.key} className="border-b">
                <Link
                  to={item.url}
                  className="px-4 py-3 flex flex-row justify-between"
                >
                  <h4 className="text-gray-900 font-medium text-lg">
                    {item.label}
                  </h4>
                  <div>
                    <ChevronRightIcon className="w-4 h-4 text-gray-500" />
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
