import React from "react"
import { Link } from "gatsby"
// import { Box, Container, Divider, Grid, Typography, Stack } from "@mui/material"
import Logo from "images/logo-primary.png"

export default function SiteFooter({ title }) {
  return (
    <footer className="py-16 lg:py-16 bg-gray-50">
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap">
          <div className="w-full lg:w-6/12">
            <div className="mb-8 lg:mb-0">
              <div className="mb-4">
                <Link to="/">
                  <span className="hidden">{title}</span>
                  <img src={Logo} alt={title} className="w-auto h-6 lg:h-7" />
                </Link>
              </div>
              <p className="text-gray-500 md:max-w-xs break-words">
                생산성과 인사 혁신을 통한 현장중심의 차별화된 솔루션을
                제공합니다.
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12">
            <div className="flex flex-row flex-wrap">
              <div className="w-1/2 lg:w-1/3">
                <div className="mb-4">
                  <h3 className="text-gray-900 text-lg font-medium mb-3">
                    Who We Are
                  </h3>
                  <ul>
                    {[
                      { key: "about", url: "/about", label: "회사소개" },
                      {
                        key: "consultants",
                        url: "/consultants",
                        label: "컨설턴트",
                      },
                      { key: "newsroom", url: "/newsroom", label: "보도자료" },
                      { key: "notices", url: "/notices", label: "공지사항" },
                    ].map(item => {
                      return (
                        <li className="text-gray-500 mt-1" key={item.key}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className="w-1/2 lg:w-1/3">
                <div className="mb-4">
                  <h3 className="text-gray-900 text-lg font-medium mb-3">
                    Our Serivces
                  </h3>
                  <ul>
                    {[
                      {
                        key: "services/human-resource-management",
                        url: "/services/human-resource-management",
                        label: "인적자원관리(HRM)",
                      },
                      {
                        key: "services/human-resource-development",
                        url: "/services/human-resource-development",
                        label: "인적자원개발(HRD)",
                      },
                      {
                        key: "services/organization-development",
                        url: "/services/organization-development",
                        label: "조직체계 개선",
                      },
                      {
                        key: "services/innovation",
                        url: "/services/innovation",
                        label: "일터혁신",
                      },
                      {
                        key: "services/manufacturing",
                        url: "/services/manufacturing",
                        label: "제조혁신",
                      },
                    ].map(item => {
                      return (
                        <li className="text-gray-500 mt-1" key={item.key}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className="w-1/2 lg:w-1/3">
                <div className="mb-4">
                  <h3 className="text-gray-900 text-lg font-medium mb-3">
                    Resources
                  </h3>
                  <ul>
                    {[
                      {
                        key: "casestudies",
                        url: "/cases",
                        label: "성공사례",
                      },
                      { key: "insights", url: "/insights", label: "인사이트" },
                    ].map(item => {
                      return (
                        <li className="text-gray-500 mt-1" key={item.key}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-gray-300 my-8" />
        <div className="text-sm text-gray-500">
          <div className="mb-3">
            <ul className="flex flex-row flex-wrap -mx-1">
              <li className="px-1">{process.env.GATSBY_COMPANY_LEGAL_NAME}</li>
              <li className="px-1">
                개인정보보호담당자: {process.env.GATSBY_COMPANY_CISO}
              </li>
              <li className="px-1">{process.env.GATSBY_COMPANY_PHONE}</li>
              <li className="px-1">{process.env.GATSBY_COMPANY_ADDRESS}</li>
            </ul>
          </div>
          <div className="flex flex-row flex-wrap">
            <ul className="flex flex-row flex-wrap -mx-1 mr-3">
              <li className="px-1">
                <Link to="/terms-of-use">이용약관</Link>
              </li>
              <li className="px-1">
                <Link to="/privacy-policy">개인정보호호정책</Link>
              </li>
            </ul>
            <p className="text-sm text-gray-500 text-left">
              © {new Date().getFullYear()}{" "}
              {process.env.GATSBY_COMPANY_LEGAL_NAME_ENG}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
