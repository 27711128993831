import ReactDOM from "react-dom"
import React, { useRef, useState, useEffect, useCallback } from "react"
import { debounce } from "lodash"
import MegaMenu from "../MegaMenu"

export default function DesktopMenu({
  activeMenu,
  onOpenMenu,
  onCloseMenu,
  source,
  location,
}) {
  const navItemRefs = useRef([])
  // const [activeMegaMenu, setActiveMegaMenu] = useState(null)
  const [indicatorPosition, setIndicatorPosition] = useState(null)
  const [indicatorWidth, setIndicatorWidth] = useState(0)

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [activeMenu])

  const handleResize = useCallback(() => {
    activeMenu && animateIndicator(activeMenu)
  }, [activeMenu])

  useEffect(() => {
    if (location?.pathname) {
      handleCloseActiveMenu()
    }
  }, [location])

  const handleCloseActiveMenu = () => {
    onCloseMenu && onCloseMenu()
    // setActiveMegaMenu(null)
    // setIndicatorPosition(null)
    // setIndicatorWidth(0)
  }

  const animateIndicator = debounce(key => {
    const index = source.findIndex(e => e.key === key)
    const item = navItemRefs.current[index]
    const linkLeft = item.getBoundingClientRect().left
    const linkWidth = item.getBoundingClientRect().width

    setIndicatorPosition(linkLeft)
    setIndicatorWidth(linkWidth)
  }, 200)

  const handleClick = (event, onAction) => {
    const id = event.target.id

    animateIndicator(id)

    onAction && onAction()
  }
  const handleMegaMenuClick = (event, onAction) => {
    const id = event.target.id

    animateIndicator(id)
    onOpenMenu(id)
    // setActiveMegaMenu(id)

    onAction && onAction()
  }

  return (
    <>
      <div className="">
        <ul className="flex flex-row flex-wrap">
          {source.map((item, index) => {
            return (
              <li
                className="px-3 xl:px-5"
                key={item.key}
                ref={element => (navItemRefs.current[index] = element)}
              >
                {item.url ? (
                  <button
                    id={item.key}
                    type="button"
                    className="cursor-pointer text-gray-500 hover:text-gray-400 transition duration-200"
                    onClick={event => handleClick(event, item.onAction)}
                  >
                    {item.label}
                  </button>
                ) : (
                  <button
                    id={item.key}
                    type="button"
                    className="cursor-pointer text-gray-500 hover:text-gray-400 transition duration-200 inline-flex flex-row items-center"
                    onClick={event => handleMegaMenuClick(event, item.onAction)}
                  >
                    {item.label}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="pointer-events-none h-4 w-4 ml-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
                <div
                  className={`absolute z-50 top-0 left-0 right-0 bg-white py-8 border-t  border-b border-gray-100 ${
                    item.key === activeMenu ? "visible" : "invisible"
                  }`}
                  style={{ top: "5rem", marginTop: "-1px" }}
                >
                  <MegaMenu items={item.children} type={item.key} />
                </div>
              </li>
            )
          })}
        </ul>
        <div
          className={`absolute bottom-0 bg-primary w-8 duration-500 transition-all ${
            !activeMenu ? "opacity-0" : "opacity-100"
          }`}
          style={{
            width: indicatorWidth,
            height: "3px",
            left: indicatorPosition,
          }}
        />
      </div>
      {activeMenu &&
        ReactDOM.createPortal(
          <div
            className="fixed inset-0 z-40 "
            onClick={handleCloseActiveMenu}
            style={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgba(0, 0, 0, .3)",
            }}
          />,
          document.getElementById("portal-backdrop")
        )}
    </>
  )
}
