import React, { useRef, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { Squash as Hamburger } from "hamburger-react"
import DesktopMenu from "components/DesktopMenu"
import { useMobileNavigation } from "components/MobileNavigation"
import Logo from "images/logo-primary.png"

export default function SiteHeader({ title, location }) {
  const headerRef = useRef(null)
  const [active, setActive] = useState(false)
  const [desktopMenuActive, setDesktopMenuActive] = useState(null)
  const mobileNavigationOepn = useMobileNavigation(state => state.open)
  const setMobileNavigationOpen = useMobileNavigation(state => state.setOpen)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [headerRef])

  const handleScroll = event => {
    setActive(window.pageYOffset > 0)
  }

  const NAV_MENU = [
    {
      key: "hpc",
      label: "Who We Are",
      children: [
        { key: "about", label: "HPC컨설팅 소개", url: "/about" },
        { key: "newsroom", label: "보도자료", url: "/newsroom" },
        { key: "notices", label: "공지사항", url: "/notices" },
      ],
    },
    {
      key: "serivces",
      label: "Our Services",
      children: [
        {
          key: "human-resource-management",
          label: "인적자원관리 (HRM)",
          description:
            "기업 특성이 조직, 전략, 문화, 구성원, 업무 등에 정합 되도록 돕습니다.",
          url: "/services/human-resource-management",
        },
        {
          key: "human-resource-development",
          label: "인적자원개발 (HRD)",
          description:
            "급변하는 환경 속에서 조직이 기술 변화에 신속히 대응하고 구성원의 직무역량을 강화 할 수 있도록 돕습니다.",
          url: "/services/human-resource-development",
        },
        {
          key: "organization",
          label: "조직개발",
          url: "/services/organization-development",
          description:
            "외부환경 변화, 기업전략 및 핵심경쟁력을 연계하여 조직체계 및 인적자원에 대한 개발이 이루어져야 합니다.",
        },
        {
          key: "innovation",
          label: "일터혁신",
          url: "/services/innovation",
          description:
            "협력적 노사관계를 기반으로 근로자의 참여에 의하여 작업조직과 작업방식을 지속적으로 개선함으로써 생산성과 근로생활의 질 향상을 지원합니다.",
        },
        {
          key: "manufacturing",
          label: "제조혁신",
          url: "/services/manufacturing",
          description:
            "대기업 공정관리자 출신 기술사와 스마트공장 전문가가 풍부한 현장경험과 노하우를 바탕으로 작업방식과 작업조직의 혁신을 지원합니다. ",
        },
      ],
    },
    { key: "consultants", label: "Consultants", url: "/consultants" },

    {
      key: "cases",
      label: "Case Studies",
      url: "/cases",
      // children: [
      //   {
      //     key: "case-archive",
      //     label: "전체보기",
      //     url: "/cases",
      //   },
      //   {
      //     key: "hmr-case",
      //     label: "인적자원관리(HRM)",
      //     url: "/cases/categories/human-resource-management",
      //   },
      //   {
      //     key: "hrd-case",
      //     label: "인적자원개발(HRD)",
      //     url: "/cases/categories/human-resource-development",
      //   },
      //   {
      //     key: "innovation",
      //     label: "일터혁신",
      //     url: "/cases/categories/innovation",
      //   },
      //   {
      //     key: "manufacturing",
      //     label: "제조혁신",
      //     url: "/cases/categories/manufacturing",
      //   },
      // ],
    },
    {
      key: "insights",
      label: "Insights",
      children: [
        {
          key: "insights-archive",
          label: "전체보기",
          url: "/insights",
        },
        {
          key: "labor-law",
          label: "노동법 이슈",
          url: "/insights/categories/labor-law",
        },
        {
          key: "management-organization",
          label: "경영과 조직",
          url: "/insights/categories/management-organization",
        },
        {
          key: "hrm-hrd",
          label: "HRM/HRD",
          url: "/insights/categories/hrm-hrd",
        },
        {
          key: "network-news",
          label: "Network news",
          url: "/insights/categories/network-news",
        },
      ],
    },
  ]

  const renderMenu = () => {
    return NAV_MENU.map(item => {
      if (item.url) {
        return {
          ...item,
          onAction: () => navigate(item.url),
        }
      } else if (item.children) {
        return {
          ...item,
          onAction: () => {
            console.log(item.key)
          },
        }
      }
    })
  }

  return (
    <>
      <header
        className={`bg-white z-50 fixed w-full top-0 left-0 right-0 h-16 lg:h-20 ${
          active ? "border-b bg-white" : ""
        } border-gray-100 flex items-center ${
          desktopMenuActive ? "bg-white" : ""
        }`}
        ref={headerRef}
      >
        <div className="w-full lg:w-full lg:container mx-auto px-4 lg:px-8 flex justify-center items-center lg:items-center lg:justify-between">
          <div className="block lg:hidden absolute left-0">
            <Hamburger
              size={20}
              Hamburger
              coolr="#666666"
              label="Menu button"
              tabIndex="0"
              hideOutline={true}
              toggled={mobileNavigationOepn}
              toggle={setMobileNavigationOpen}
            />
          </div>
          <div>
            <Link to="/">
              <span className="hidden">{title}</span>
              <img src={Logo} alt={title} className="w-auto h-6 lg:h-7" />
            </Link>
          </div>
          <div className="hidden lg:flex">
            <DesktopMenu
              activeMenu={desktopMenuActive}
              onOpenMenu={id => setDesktopMenuActive(id)}
              onCloseMenu={() => setDesktopMenuActive(null)}
              source={renderMenu()}
              location={location}
            />
          </div>
          <div className="hidden lg:block" style={{ top: "50%" }}>
            <Link
              className="text-white bg-primary rounded-full text-sm lg:text-base px-3 lg:px-4 py-2"
              to="/contact"
            >
              상담신청
            </Link>
          </div>
        </div>
      </header>
    </>
  )
}
